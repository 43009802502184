import React from "react"
import Header from "../components/header"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import IndexStyle from "../styles/index.module.css"
import ErrStyle from "../styles/404.module.css"

export default () => (
  <div>
    <Helmet>
      <title>Error!</title>
    </Helmet>
    <Header />
    <div className={IndexStyle.contentContainer}>
      <div className={ErrStyle.bodyText}>
        Uh oh, looks like something went wrong...
      </div>
      <div className={ErrStyle.buttonContainer}>
        <Link to="/" className={ErrStyle.button}>
          Take me back to civilization
        </Link>
      </div>
    </div>
  </div>
)
